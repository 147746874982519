import React from "react";
import "./Layout.css";
import Navigation from "../Navigation/Navigation";

const Layout = ({ children, scrollFunc }) => {
  return (
    <div className="layout" id="header">
      <Navigation scrollFunc={scrollFunc} />
      {children}
    </div>
  );
};

export default Layout;
