import React from "react";
import "./Skills.css";

const skills = [
  "React",
  "TypeScript",
  "JavaScript",
  "HTML",
  "CSS / SCSS",
  "Redux",
  "React Query",
  "React Router",
  "React Testing Library",
  "Jest",
  "Git",
  "NPM / Yarn",
  "Figma",
];

// const skills = [
//   { JavaScript: "★★★☆" },
//   { TypeScript: "★★★☆" },
//   { React: "★★★☆" },
//   { "Next.js": "★★★☆" },
//   { HTML: "★★★★" },
//   { Redux: "★★☆☆" },
//   { "CSS / SCSS": "★★★☆" },
//   { Jest: "★★☆☆" },
//   { Enzyme: "★☆☆☆" },
//   { Figma: "★★☆☆" },
//   { "Adobe Illustrator": "★★☆☆" },
//   { SQL: "★★☆☆" },
//   { Git: "★★☆☆" },
//   { NPM: "★★☆☆" },
//   { Tailwind: "★★★☆" },
//   { "Styled components": "★★☆☆" },
//   { "Frontend development": "★★★☆" },
//   { "Responsive Web Design": "★★★☆" },
//   { "Graphic (UI) design": "★★☆☆" },
// ];

const Skills = () => {
  return (
    <>
      <h2>Skills</h2>
      <div className="skills-container">
        {skills.map((skill, id) => {
          return (
            <div key={id} className="skills-element">
              <p>{skill}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Skills;
