import React from "react";
import "./App.css";
import Layout from "../Layout/Layout";
import Hero from "../Hero/Hero";
import AboutMe from "../AboutMe/AboutMe";
import Skills from "../Skills/Skills";
import Experience from "../Experience/Experience";
import Button from "../Shared/Button/Button";

const App = () => {
  const scrollToSection = (sectionName) => {
    let element = document.getElementById(sectionName);
    let position = element.offsetTop;
    window.scrollTo({
      top: position - 24,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Layout scrollFunc={scrollToSection}>
      <section>
        <Hero />
        <hr className="line"></hr>
      </section>
      <section id="about-me">
        <AboutMe />
        <hr className="line"></hr>
      </section>
      <section id="skills">
        <Skills />
        <hr className="line"></hr>
      </section>
      <section id="work-experience">
        <Experience />
        <hr className="line"></hr>
      </section>
      <div className="goup-container">
        <Button buttonStyle="light" onClick={() => scrollToSection("header")}>
          GO UP
        </Button>
      </div>
    </Layout>
  );
};

export default App;
