import React from "react";
import "./Navigation.css";

const Navigation = ({ scrollFunc }) => {
  return (
    <ul className="nav-container">
      <li className="nav-element" onClick={() => scrollFunc("about-me")}>
        ABOUT
      </li>
      <li className="nav-element" onClick={() => scrollFunc("skills")}>
        SKILLS
      </li>
      <li className="nav-element" onClick={() => scrollFunc("work-experience")}>
        WORK EXPERIENCE
      </li>
    </ul>
  );
};

export default Navigation;
