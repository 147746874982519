import React from "react";
import "./Experience.css";

const workExperience = [
  {
    jobTitle: "Frontend Developer",
    time: "15.08.2022 - current",
    company: "Zeta Alpha",
    description:
      "Building new features and mantaining the current functionality for knowledge discovery and management platform.",
    listElements: [
      "- React,",
      "- TypeScript,",
      "- React Testing Library,",
      "- Jest,",
      "- MUI,",
      "- Figma",
    ],
  },
  {
    jobTitle: "Frontend Developer",
    time: "01.03.2021 - 31.07.2022",
    company: "Content Stadium",
    description:
      "Creating interactive HTML Canvas templates based on Photoshop designs.",
    listElements: ["- Farbric.js,", "- TypeScript"],
  },
  {
    jobTitle: "Web Development Intern",
    time: "01.09.2020 - 31.01.2021",
    company: "Tellow",
    description:
      "As an intern, I was cooperating with another intern on building an SSR app, researching tools that could be used, and implementing the design.",
    listElements: [
      "- React,",
      "- Next.js,",
      "- TypeScript,",
      "- Tailwind,",
      "- Storybook",
    ],
  },
];

const Experience = () => {
  return (
    <>
      <h2>Work experience</h2>
      <h3>Frontend Development Experience</h3>

      {workExperience.map((work, index) => {
        return (
          <>
            <div key={index}>
              <h5>{work.jobTitle}</h5>
              <div className="experience-description-timecompany">
                <p>{work.time}</p>
                <p>{work.company}</p>
              </div>
              <p className="experience-description-tasks">
                {work.description} <br></br>Technologies used:
              </p>
              <ul className="experience-list">
                {work.listElements.map((el, index) => {
                  return (
                    <li key={index} className="experience-listel">
                      <strong>{el}</strong>
                    </li>
                  );
                })}
              </ul>
            </div>
            <br></br>
            <br></br>
          </>
        );
      })}

      <h3>Other Work Experience</h3>
      <div>
        <p className="experience-description-tasks">
          I've worked as a Digital Marketer for over 6 years.
        </p>
      </div>
    </>
  );
};

export default Experience;
