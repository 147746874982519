import React from "react";
import "./AboutMe.css";

import LinkedinLogo from "../../assets/LI-Logo.png";
import GithubLogo from "../../assets/GitHub_Logo.png";

const AboutMe = () => {
  const clickHandler = (link) => {
    window.open(link, "_blank");
    console.log("clicked", link);
  };
  return (
    <>
      <h2>About me</h2>
      <div className="aboutMe-container">
        <div className="aboutMe-text">
          <p>
            I am a frontend developer with 3 years of experience, focusing on
            React, TypeScript, React Testing Library, Redux and React Query.
          </p>
          <p>
            My aim is to write clean, clear and maintainable code. In order to
            reach it, I am continusouly learning and improving my skills.
          </p>
        </div>
        <div className="aboutMe-links">
          <div>
            <p>See more:</p>
            <ul>
              <li>
                <img
                  src={LinkedinLogo}
                  alt="Linkedin"
                  onClick={() =>
                    clickHandler(
                      "https://www.linkedin.com/in/joanna-kr%C3%B3l-65634795/"
                    )
                  }
                ></img>
              </li>
              <li>
                <img
                  src={GithubLogo}
                  alt="GitHub"
                  onClick={() => clickHandler("https://github.com/joannabkrol")}
                ></img>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMe;
