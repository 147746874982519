import React from "react";
import Illustration from "../../assets/pixeltrue-hero.svg";
import "./Hero.css";
import Button from "../Shared/Button/Button";

const Hero = () => {
  const contactHandler = () => {
    console.log("Clicked contact button");
    window.open(
      "https://www.linkedin.com/in/joanna-kr%C3%B3l-65634795/",
      "_blank"
    );
  };
  return (
    <>
      <div className="hero-container">
        <div className="hero-description">
          <h1>Joanna Król</h1>
          <h4>Frontend Developer</h4>
          <p className="hero-description-text">
            Hi there! Thank you for stopping by. Take a look at my portfolio and
            contact me via Linkedin.
          </p>
          <Button buttonStyle="dark" onClick={() => contactHandler()}>
            GO TO LINKEDIN
          </Button>
        </div>
        <div className="hero-image">
          <img
            src={Illustration}
            alt="Illustration of a women working on the laptop."
          ></img>
        </div>
      </div>
    </>
  );
};

export default Hero;
